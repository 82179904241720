.container {
  margin-bottom: 8rem;
}

.container h2 {
  font-size: 4.4rem;
  margin-bottom: 3.2rem;
}

.container h2 span {
  display: block;
  font-family: "Inter";

  text-transform: uppercase;
  font-size: 2rem;
  color: var(--color__green--dark);
  letter-spacing: 0.5rem;
  font-weight: 900;
}

.container p {
  max-width: 78rem;
  line-height: 2;
  font-family: "Inter";
}

/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .container h2 {
    font-size: 3.6rem;
    margin-bottom: 2.4rem;
  }

  .container h2 span {
    font-size: 1.8rem;
  }
}
/***********************************/
/* BELOW 400px */
/***********************************/
@media (max-width: 25em) {
  .container h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  .container h2 span {
    font-size: 1.6rem;
  }
}
