.headerContainer {
  max-width: 120rem;
  margin: 0 auto;
}

.flexContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8rem;
}

/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .container {
    padding: 15rem 3.2rem 12.8rem;
  }

  .flexContainer {
    flex-direction: column;
    align-items: center;
  }
}
