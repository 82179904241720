.container {
  display: flex;
  padding: 0 0 6.4rem;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.line {
  height: 1px;
  flex-grow: 1;
  background-color: var(--main-color);
}

.container ul {
  margin: 0 6.4rem;
  display: flex;
  gap: 3.6rem;
  list-style: none;
}

.container svg {
  display: block;
  height: 3.6rem;
  transition: all 0.2s;
}

.container svg:hover {
  transform: scale(1.1);
}
