.h1 {
  font-size: 6.2rem;
  text-align: center;
  margin-bottom: 12.8rem;
}

.h2 {
  font-size: 3.6rem;
  margin-bottom: 6.4rem;
}

.h3 {
  font-size: 2.4rem;
  color: var(--color__grey--medium-dark);
  margin-bottom: 1.6rem;
  font-family: "Inter";
}

/***********************************/
/* BELOW 900px */
/***********************************/
@media (max-width: 56.25em) {
  .h1 {
    font-size: 5.2rem;
    text-align: center;
    margin-bottom: 9.6rem;
  }
}
