.nav {
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.nav img {
  height: 6.4rem;
  text-decoration: none;
  opacity: 0;
  animation: links 1s ease-out forwards 0.2s;
}

.nav ul {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 6.4rem;
}

.link {
  opacity: 0;
  display: inline-block;
}

.link--1 {
  animation: links 1s ease-out forwards 0.2s;
}
.link--2 {
  animation: links 1s ease-out forwards 0.3s;
}
.link--3 {
  animation: links 1s ease-out forwards 0.4s;
}

.link a:link,
.link a:visited {
  padding: 0.4rem;
  text-decoration: none;
  color: var(--main-color);
  font-family: "Inter";
  font-weight: 600;
  border-bottom: 1px solid black;
}

.link a:hover,
.link a:active {
  border-bottom: 3px solid black;
}

@keyframes links {
  0% {
    transform: translateY(-10rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/***********************************/
/* BELOW 800px */
/***********************************/
@media (max-width: 50em) {
  .nav img {
    height: 4.8rem;
  }

  .nav ul {
    gap: 4.8rem;
    /* font-size: 1.6rem; */
  }
}
/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .nav ul {
    gap: 3.2rem;
    font-size: 1.6rem;
  }
}
/***********************************/
/* BELOW 400px */
/***********************************/
@media (max-width: 25em) {
  .nav {
    padding: 1rem 2rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
