.gradientToBottom {
  background-image: linear-gradient(
    to bottom,
    var(--color__grey--light-medium),
    var(--color__grey--light)
  );
}

.lessonsLearned {
  max-width: 90rem !important;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

.listItem {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.listItem svg {
  height: 3rem;
  flex-shrink: 0;
}

.text {
  font-size: 2.4rem;
}

.lessonsLearned .text:not(:last-child) {
  margin-bottom: 3.2rem;
}
