.Link:link,
.Link:visited {
  font-size: 2.4rem;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  padding: 0.8rem;
  font-weight: 700;
}

.Link:link::before,
.Link:visited::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: var(--main-color);
  transition: all 0.2s;
}

.Link:hover::before,
.Link:active::before {
  height: 0.6rem;
  width: 100%;
  background-color: var(--main-color);
}

/* TEXT LINKS */

.textLink:link,
.textLink:visited {
  font-size: inherit;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--main-color);

  font-weight: 700;
}

.textLink:link::before,
.textLink:visited::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: var(--main-color);
}

.textLink:hover::before,
.textLink:active::before {
  height: 4px;
  width: 100%;
  background-color: var(--main-color);
}

.homePageLink img {
  height: 6.4rem;
  border-radius: 50%;

  transition: all 0.2s;
}

.homePageLink img:hover,
.homePageLink img:active {
  transform: scale(1.1);
}

/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .Link:link,
  .Link:visited {
    font-size: 1.8rem;
  }
}
/***********************************/
/* BELOW 400px */
/***********************************/
@media (max-width: 25em) {
  .Link:link,
  .Link:visited {
    word-break: break-all;
  }
}
