.grid {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  gap: 4rem 8rem;
}

/***********************************/
/* BELOW 500px */
/***********************************/
@media (max-width: 31.25em) {
  .grid {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    margin: 0 auto;
  }
}
