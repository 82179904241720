.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6.4rem;
  margin-bottom: 6.4rem;
}

.linkHomePage {
  display: flex;
  justify-content: center;
}
