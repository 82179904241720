.container {
  margin-top: 8rem;

  display: flex;
  gap: 9.6rem;
  flex-wrap: wrap;
  justify-content: center;
}

.container img {
  max-height: 50rem;
  box-shadow: 0 1.5rem 3rem rgb(0 0 0 / 40%);
}
