.container {
  max-width: 140rem;
  padding: 12.8rem 6.4rem 15rem;
  margin: 0 auto;
}

.titleAndPhotoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6.4rem;
  margin-bottom: 12.8rem;
}

.title {
  font-size: 4.8rem;

  display: flex;
  flex-direction: column;
}

.titleLine {
  opacity: 0;
}

.colorText {
  margin-left: 2.4rem;
}

.titleLine--1 {
  margin-bottom: 3.2rem;
  animation: enterFromBottom 1s ease-out forwards 0.4s;
}
.titleLine--2 {
  animation: enterFromBottom 1s ease-out forwards 0.6s;
}
.titleLine--3 {
  animation: enterFromBottom 1s ease-out forwards 0.8s;
}
.titleLine--4 {
  animation: enterFromBottom 1s ease-out forwards 1s;
}

.photo {
  display: block;
  max-height: 50rem;
  min-width: 30rem;

  opacity: 0;
  animation: enterFromTop 1s ease-out forwards 0.4s;

  grid-column: 2/4;
  grid-row: 1;
  justify-self: center;
  align-self: center;
}

.column p:first-child {
  margin-bottom: 3.2rem;
}

.textContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4.8rem;
  row-gap: 2.4rem;
}

.paragraph {
  line-height: 2;
  opacity: 0;
  animation: enterFromBottom 1s ease-out forwards 1.2s;
}

.paragraph--1 {
  grid-column: 1;
  grid-row: 1;
}
.paragraph--2 {
  grid-column: 1;
  grid-row: 2;
}
.paragraph--3 {
  grid-column: 2;
  grid-row: 1;
}
.paragraph--4 {
  grid-column: 2;
  grid-row: 2;
}

@keyframes enterFromBottom {
  0% {
    transform: translateY(5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes enterFromTop {
  0% {
    transform: translateX(10rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/***********************************/
/* BELOW 1056px */
/***********************************/
@media (max-width: 66em) {
  .titleAndPhotoContainer {
    flex-direction: column;
  }
}
/***********************************/
/* BELOW 800px */
/***********************************/
@media (max-width: 50em) {
  .textContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3.2rem;
  }
  .paragraph--1 {
    grid-column: 1;
    grid-row: 1;
  }
  .paragraph--2 {
    grid-column: 1;
    grid-row: 2;
  }
  .paragraph--3 {
    grid-column: 1;
    grid-row: 3;
  }
  .paragraph--4 {
    grid-column: 1;
    grid-row: 4;
  }
}
/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .container {
    padding: 6.4rem 4.8rem 8rem;
  }
  .titleAndPhotoContainer {
    margin-bottom: 8rem;
  }
  .title {
    font-size: 3.6rem;
  }
  .photo {
    animation: enterFromBottom 1s ease-out forwards 1.2s;
  }
}
/***********************************/
/* BELOW 400px */
/***********************************/
@media (max-width: 25em) {
  .container {
    max-width: 140rem;
    padding: 4.8rem 3.2rem 6.4rem;
    margin: 0 auto;
  }
  .title {
    font-size: 3rem;
  }
  .colorText {
    margin-left: 1.6rem;
  }
}
