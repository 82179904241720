.title {
  font-size: 3.2rem;
  grid-row: 1/2;
  grid-column: 1/-1;
  width: 100%;
  padding-bottom: 4.8rem;
  border-bottom: 1px solid var(--main-color);
  text-align: center;
  margin-bottom: 12.8rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.2rem;
  row-gap: 10rem;
}

.icon {
  display: inline-block;
  justify-self: center;
  align-self: center;
}

.icon svg {
  height: 8rem;
}

/***********************************/
/* BELOW 800px */
/***********************************/
@media (max-width: 50em) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .title {
    font-size: 3rem;
    margin-bottom: 8rem;
  }
  .icon svg {
    height: 6rem;
  }
}
