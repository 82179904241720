.CapturesContainer {
  padding: 6.4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.CapturesContainer img {
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.4);
}

.capturesDesktop {
  max-width: 70%;
  height: auto;
}

.capturesMobile {
  max-width: 25%;
  height: auto;
}

/***********************************/
/* BELOW 500px */
/***********************************/
@media (max-width: 31.25em) {
  .CapturesContainer {
    flex-direction: column;
    gap: 6.4rem;
  }

  .capturesDesktop {
    max-width: 100%;
  }

  .capturesMobile {
    max-width: 50%;
  }
}
