.span {
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.green {
  background-image: linear-gradient(
    to right,
    var(--color__green--dark),
    var(--color__green--light)
  );
}

/* .orange {
  background-image: linear-gradient(
    to right,
    var(--color__yellow--dark),
    var(--color__yellow--light)
  );
}

.blue {
  background-image: linear-gradient(to right, var(--color__blue--light), var(--color__blue--dark));
} */
