/* 
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

:root {
  /* COLORS */
  --main-color: #212529;
  --color__grey--light: #f8f9fa;
  --color__grey--light-darker: #f1f3f5;

  --color__grey--light-medium: #dee2e6;
  --color__grey--medium: #adb5bd;
  --color__grey--medium-dark: #495057;

  --color__green--dark: #0aa073;
  --color__green--light: #34cd50;

  --color__blue--light: #1c7ed6;
  --color__blue--dark: #4263eb;

  --color__yellow--light: #fab005;
  --color__yellow--dark: #f08c00;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Libre Baskerville", "Inter", sans-serif, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  line-height: 1.5;
  background-color: var(--color__grey--light);
  color: var(--main-color);
  font-size: 2rem;
}

html,
body {
  height: 100%;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

::-webkit-scrollbar {
  background: var(--color__grey--light);
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--color__grey--medium);
  border-radius: 10rem;
}

/***********************************/
/* BELOW 800px */
/***********************************/
@media (max-width: 50em) {
  html {
    font-size: 56.25%;
  }
}
/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}
/***********************************/
/* BELOW 350px */
/***********************************/
@media (max-width: 21.875em) {
  html {
    font-size: 43.75%;
  }
}
