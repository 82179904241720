.headerGrid {
  font-family: "Inter";
  display: flex;
  gap: 4.8rem;
  flex-wrap: wrap-reverse;
}
.resumeItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.headerList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  column-gap: 3rem;
  line-height: 1.4;
  font-family: "Inter";
}
