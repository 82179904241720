.layout {
  padding: 15rem 0;
}

.layout p {
  line-height: 1.7;
  color: var(--color__grey--medium-dark);
  font-family: "Inter";
  font-weight: 500;
}

.layout p:not(:last-child) {
  margin-bottom: 1.2rem;
}

.content {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 5rem;
}

.contentCaptures {
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 5rem;
}

.gradientToRight {
  background-image: linear-gradient(
    to right,
    var(--color__grey--light-medium),
    var(--color__grey--light)
  );
}

.gradientToBottom {
  background-image: linear-gradient(
    to bottom,
    var(--color__grey--light-medium),
    var(--color__grey--light)
  );
}

/***********************************/
/* BELOW 1000px */
/***********************************/
@media (max-width: 62.5em) {
  .layout {
    padding: 12.8rem 0;
  }
}
/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .layout {
    padding: 9.6rem 0;
  }
  .content {
    padding: 0 3rem;
  }
}
/***********************************/
/* BELOW 400px */
/***********************************/
@media (max-width: 25em) {
  .layout {
    padding: 8rem 0;
  }
  .content {
    padding: 0 2rem;
  }
}
