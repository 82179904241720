.homePageLink {
  position: absolute;
  top: 6.4rem;
  left: 6.4rem;
}

/***********************************/
/* BELOW 1000px */
/***********************************/
@media (max-width: 62.5em) {
  .homePageLink {
    top: 5rem;
    left: 5rem;
  }
}
/***********************************/
/* BELOW 900px */
/***********************************/
@media (max-width: 56.25em) {
  .homePageLink {
    top: 3.2rem;
    left: 3.2rem;
  }
}
/***********************************/
/* BELOW 600px */
/***********************************/
@media (max-width: 37.5em) {
  .homePageLink {
    top: 1.6rem;
    left: 1.6rem;
  }
}
