.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  font-size: 7.4rem;
  margin-bottom: 8rem;
}

.content h2 {
  margin-bottom: 4.8rem;
}

.content a,
.content h2 {
  font-family: "Inter";
}
