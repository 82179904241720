.steps:not(:last-child) {
  padding: 8rem 0;
}

.steps:last-child {
  padding-top: 8rem;
}

.steps h3 {
  font-size: 3.2rem;
  text-align: center;
}
