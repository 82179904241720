.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container h3 {
  color: var(--main-color);
  padding-bottom: 2rem;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.container p {
  width: 100%;
  line-height: 1.5;
  font-family: "Inter";
}

.card {
  perspective: 200rem;
  position: relative;
  width: 57rem;
  height: 30rem;
}

.cardSide {
  width: 100%;
  height: 100%;
  transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.4);
}

.cardSideFront {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardSideFront--1 {
  background-image: url(../../../static/thumbnails/passphrase.webp);
  background-position: top;
}
.cardSideFront--2 {
  background-image: url(../../../static/thumbnails/flags.webp);
  background-position: top;
}
.cardSideFront--3 {
  background-image: url(../../../static/thumbnails/jm.webp);
  background-position: top;
}

.cardSideBack {
  background-image: linear-gradient(
    to bottom right,
    var(--color__grey--light),
    var(--color__grey--light-darker)
  );
  transform: rotateX(-180deg);
  padding: 4.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container:hover .cardSideFront {
  transform: rotateX(180deg);
}

.container:hover .cardSideBack {
  transform: rotateX(0deg);
}

/***********************************/
/* BELOW 500px */
/***********************************/
@media (max-width: 31.25em) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card {
    width: 100%;
  }
}
/***********************************/
/* BELOW 400px */
/***********************************/
@media (max-width: 25em) {
  .container h3 {
    font-size: 2rem;
  }
}
