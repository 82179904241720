.CapturesContainer {
  margin: 0 auto 9.6rem;
  max-width: 100rem;
}

.CapturesContainer img {
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.4);
}

.captureMDN {
  width: 100%;
}

/***********************************/
/* BELOW 900px */
/***********************************/
@media (max-width: 56.25em) {
  .grid {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    margin: 0 auto;
  }
}
/***********************************/
/* BELOW 500px */
/***********************************/
@media (max-width: 31.25em) {
  .CapturesContainer {
    display: none;
  }
}
